<template>
  <div>
    <!-- <Layout>
    <PageHeader :title="title" :items="items" />-->
    <form @submit.prevent="save">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 mx-auto">
          <div class="add_coin_form_box mb-3">
            <div class="row middle-row align-items-center">
              <!-- NAME -->
              <b-col md="12" class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                <b-form-group id="input-group-1" label="Name:" label-for="input-1">
                  <b-form-input
                    id="input-1"
                    v-model.trim="$v.form.name.$model"
                    type="text"
                    placeholder="Enter name"
                  ></b-form-input>
                </b-form-group>
                <div class="error" v-if="submitted && !$v.form.name.required">Name is required</div>
              </b-col>

              <!-- SYMBOL -->
              <b-col md="12" class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                <b-form-group id="input-group-1" label="Symbol:" label-for="input-1">
                  <b-form-input
                    id="input-1"
                    v-model.trim="$v.form.symbol.$model"
                    type="text"
                    placeholder="Enter symbol name"
                  ></b-form-input>
                </b-form-group>
                <div class="error" v-if="submitted && !$v.form.symbol.required">Symbol is required</div>
              </b-col>

              <!-- HASH TAGS -->
              <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                <div class="form-group">
                  <label for="selectpair" class="form-label">Hash tags:</label>
                  <multiselect
                    v-model.trim="$v.form.hashtag.$model"
                    placeholder="Search hash tags"
                    :options="hashtag_options"
                    :multiple="true"
                    :taggable="true"
                    @tag="addTag"
                  ></multiselect>
                  <!-- <div class="error" v-if="submitted && !$v.form.hashtag.required">
                                            Tag is required
                  </div>-->
                </div>
              </div>

              <!-- PARTNERSHIPS -->
              <div class="col-xl-6 col-lg-6 col-md-6 col-12 mb-3">
                <div class="form-group">
                  <label for="selectpair" class="form-label">Partnership Links:</label>
                  <multiselect
                    v-model.trim="$v.form.partnership.$model"
                    placeholder="Search Partnerships"
                    :options="partnership_options"
                    label="name"
                    track-by="name"
                    :multiple="true"
                    :taggable="true"
                    @tag="addTag1"
                  ></multiselect>
                  <!-- <div class="error" v-if="submitted && !$v.form.partnership.required">
                                            Please add partnership
                  </div>-->
                  <!-- <div class="error" v-if="submitted && !$v.form.partnership.$each.$anyDirty && $v.form.partnership.required">
                                        Please provide a Valid Url
                  </div>-->
                </div>
              </div>

              <!-- TOTAL SCORE -->
              <b-col class="col-xl-1 col-lg-1 col-md-1 col-1 mb-3">
                <b-form-group id="input-group-1" label="Total Score:" label-for="input-1">
                  <b-form-input
                    id="input-1"
                    v-model.trim="$v.form.total_score.$model"
                    type="text"
                    placeholder="Add total score"
                    readonly
                  ></b-form-input>
                </b-form-group>
                <div
                  class="error"
                  v-if="submitted && !$v.form.total_score.required"
                >Total score required</div>
              </b-col>

              <!-- VERIFICATION SCORE -->
              <div class="col-xl-5 col-lg-5 col-md-5 col-5 mb-3">
                <div
                  class="form-group"
                  :class="{ 'form-group--error': $v.form.verify_score.$error }"
                >
                  <label for="selectbuy" class="form-label">Verification Score:</label>
                  <multiselect
                    :options="verify_options"
                    v-model="$v.form.verify_score.$model"
                    track-by="key"
                    label="value"
                  ></multiselect>
                  <div
                    class="error"
                    v-if="submitted && !$v.form.verify_score.required"
                  >Verification score is required</div>
                </div>
              </div>

              <!-- White paper Link Pdf -->
              <div class="col-xl-5 col-lg-5 col-md-5 col-10 mb-3">
                <div class="form-group" :class="{ 'form-group--error': $v.form.image.$error }">
                  <label for="formimage" class="form-label">White paper link:</label>
                  <small class="text-muted ms-2">
                    <strong>Note :-</strong>
                    <span class="text-danger">1. Only pdf.</span>
                  </small>
                  <!-- <img :src="form.image" alt="" class="img-fluid" /> -->
                  <div class="d-flex align-items-center">
                    <input
                      class="form-control"
                      ref="fileupload"
                      type="file"
                      id="formFile"
                      accept=".pdf"
                      @change="pdfupload"
                    />
                    <a :href="pdflink" target="_blank">
                      <img class="pt-3" v-if="form.image" src="@/assets/images/pdf.png" alt="icons" />
                    </a>
                  </div>
                  <div class="error" v-if="submitted && !$v.form.image.required">Pdf is required</div>
                </div>
              </div>
              <!-- <div class="col-xl-1 col-lg-1 col-md-1 col-2 mb-3">
                                    <div class="form-group">
                                        <div class="currency-image">
                                            <img :src="form.image" alt="" class="img-fluid" />
                                        </div>
                                    </div>
              </div>-->

              <!-- Token Url -->
              <div class="col-3 mb-3">
                <label class="fw-bold">Token Url</label>
                <div class="input-group form_liquidity">
                  <input
                    class="form-control shadow-none m-0"
                    placeholder="https://example.com/"
                    id="input1"
                    ref="cloneaddress"
                    aria-label="Username"
                    type="url"
                    v-model.trim="$v.form.token_url.$model"
                    aria-describedby="basic-addon1"
                  />
                  <span
                    style="cursor: pointer;"
                    title="Paste"
                    data-toggle="tooltip"
                    data-placement="top"
                    class="input-group-text shadow-none border-0"
                    id="basic-addon1"
                    @click="copyaddress('input1', 'token_url')"
                  >
                    <img src="@/assets/images/paste.svg" alt="icons" class="img-fluid" />
                  </span>
                </div>
                <div
                  class="error"
                  v-if="submitted && !$v.form.token_url.required"
                >Token url is required</div>
                <div
                  class="error"
                  v-if="submitted && !$v.form.token_url.url"
                >Token url should be a valid URL</div>
              </div>

              <!-- VIDEO LINK -->
              <div class="col-3 mb-3">
                <label class="fw-bold">Video Link</label>
                <div class="input-group form_liquidity">
                  <input
                    class="form-control shadow-none m-0"
                    placeholder="https://www.example.com/"
                    id="input2"
                    ref="cloneaddress"
                    type="url"
                    v-model.trim="$v.form.video_link.$model"
                    aria-describedby="basic-addon2"
                  />
                  <span
                    style="cursor: pointer;"
                    title="Paste"
                    data-toggle="tooltip"
                    data-placement="top"
                    class="input-group-text shadow-none border-0"
                    id="basic-addon2"
                    @click="copyaddress('input2', 'video_link')"
                  >
                    <img src="@/assets/images/paste.svg" alt="icons" class="img-fluid" />
                  </span>
                </div>
                <div
                  class="error"
                  v-if="submitted && !$v.form.video_link.required"
                >Video link is required</div>
                <div
                  class="error"
                  v-if="submitted && !$v.form.video_link.url"
                >Video link should be a valid URL</div>
              </div>

              <!-- UPLOAD IMAGE -->
              <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-10 mb-3">
                                <div class="form-group">
                                    <label for="formimage" class="form-label">Gallery Choose Image:</label>
                                    <small class="text-muted ms-2">
                                        <strong>Note :- </strong>
                                        <span class="text-danger">1. Only PNG Image. 2.Image Dimension 84x84 .</span></small>
                                    <input class="form-control" ref="fileupload" type="file" id="formFile" accept="image/*" @change="onChangeImage" />
                                    <div class="error" v-if="submitted && !$v.form.image.required">
                                        Image is required
                                    </div>
                                </div>
              </div>-->

              <!-- START DATE -->
              <div class="col-md-2 mb-3">
                <label for="form-label">Started At:</label>
                <b-input-group class="mb-1">
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="$v.form.started_at.$model"
                      button-only
                      right
                      today-button
                      reset-button
                      locale="en-US"
                      aria-controls="form-label"
                      @context="onContext"
                    ></b-form-datepicker>
                  </b-input-group-append>
                  <b-form-input
                    id="form-label"
                    v-model="$v.form.started_at.$model"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                  ></b-form-input>
                </b-input-group>
                <div class="error" v-if="submitted && !$v.form.started_at.required">Date is required</div>
              </div>

              <!-- EXPIRED DATE -->
              <div class="col-md-2 mb-3">
                <label for="form-label">Expired At:</label>
                <b-input-group class="mb-1">
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="$v.form.expired_at.$model"
                      button-only
                      right
                      today-button
                      reset-button
                      locale="en-US"
                      aria-controls="form-label"
                      @context="onContext1"
                    ></b-form-datepicker>
                  </b-input-group-append>
                  <b-form-input
                    id="form-label"
                    v-model="$v.form.expired_at.$model"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                  ></b-form-input>
                </b-input-group>
                <div class="error" v-if="submitted && !$v.form.expired_at.required">Date is required</div>
              </div>

              <!-- distribution DATE -->
              <div class="col-md-2 mb-3">
                <label for="form-label">Distribution At:</label>
                <b-input-group class="mb-1">
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="form.distribution_at"
                      button-only
                      right
                      today-button
                      reset-button
                      locale="en-US"
                      aria-controls="form-label"
                      @context="onContext1"
                    ></b-form-datepicker>
                  </b-input-group-append>
                  <b-form-input
                    id="form-label"
                    v-model="form.distribution_at"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                  ></b-form-input>
                </b-input-group>
                <!-- <div class="error" v-if="submitted && !$v.form.distribution_at.required">Date is required</div> -->
              </div>

              <!-- TABLE ROW START  -->
              <div class="col-md-12">
                <div class="form-box">
                  <div class="mb-2 d-flex align-items-center">
                    <div class="w-50">
                      <label for="text">Gallery:</label>
                    </div>
                    <!-- Add -->
                    <div class="w-50 text-end">
                      <button
                        title="Add new row"
                        data-toggle="tooltip"
                        data-placement="top"
                        type="button"
                        id="btn"
                        class="btn shadow-none border_box"
                        @click="gallery.push('')"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="var(--navy-blue)"
                            viewBox="0 0 448 512"
                          >
                            <path
                              d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                  <div
                    v-for="(item, index) in gallery"
                    :key="index"
                    class="border_box d-flex align-items-center px-3 py-2 mb-3"
                  >
                    <!-- GALLERY LINK -->
                    <div class="w-100">
                      <div class="input-group form_liquidity">
                        <input
                          class="form-control shadow-none m-0 ms-2"
                          placeholder="https://www.example.com/data/example.png"
                          id="input4"
                          ref="cloneaddress"
                          type="file"
                          @change="galleryImages(index)"
                          aria-describedby="basic-addon4"
                        />
                        <span
                          v-if="item"
                          style="cursor: pointer; max-height:35px; max-width:100px;"
                          class="input-group-text shadow-none border-0"
                          id="basic-addon4"
                        >
                          <a target="_blank" :href="item">
                            <img
                              :src="item"
                              alt="icons"
                              style="cursor: pointer; max-height:35px; max-width:50px;"
                              class="img-fluid"
                            />
                          </a>
                        </span>
                      </div>
                      <div
                        class="error"
                        v-if="submitted && !$v.form.gallery.required"
                      >Image is required</div>
                    </div>
                    <!-- Delete  -->
                    <div class="ms-2">
                      <button
                        title="Delete"
                        data-toggle="tooltip"
                        data-placement="top"
                        type="button"
                        id="btn"
                        class="btn shadow-none border_box"
                        style="cursor: pointer;"
                        @click="index!=0?gallery.splice(index, 1):'return false'"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="var(--navy-blue)"
                          viewBox="0 0 320 512"
                        >
                          <path
                            d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- TABLE ROW END -->

              <!-- TABLE ROW START  -->
              <div class="col-md-12">
                <div class="form-box">
                  <div class="mb-2 d-flex align-items-center">
                    <div class="w-50">
                      <label for="text">Social Media Links:</label>
                    </div>
                    <!-- Add -->
                    <div class="w-50 text-end">
                      <button
                        title="Add new row"
                        data-toggle="tooltip"
                        data-placement="top"
                        type="button"
                        id="btn"
                        class="btn shadow-none border_box"
                        @click="socials.push({ link_key: '', link_value: '',})"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="var(--navy-blue)"
                            viewBox="0 0 448 512"
                          >
                            <path
                              d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                  <div
                    v-for="(item, index) in $v.socials.$each.$iter"
                    :key="index"
                    class="border_box d-flex align-items-center px-3 py-2 mb-3"
                  >
                    <!-- SOCIAL MEDIA NAME -->
                    <div class="w-50">
                      <div class="form-group">
                        <multiselect
                          v-model="item.link_key.$model"
                          track-by="keys"
                          label="values"
                          :options="social_options"
                        ></multiselect>
                      </div>
                      <!-- <div class="error" v-if="submitted && !item.link_key.required">
                                                    Social Platform is required
                      </div>-->
                    </div>

                    <!-- SOCIAL MEDIA LINK -->
                    <div class="w-50">
                      <div class="input-group form_liquidity">
                        <input
                          class="form-control shadow-none m-0 ms-2"
                          placeholder="https://www.example.com/users/"
                          id="input3"
                          ref="cloneaddress"
                          type="url"
                          v-model="item.link_value.$model"
                          aria-describedby="basic-addon3"
                        />
                        <span
                          style="cursor: pointer;"
                          class="input-group-text shadow-none border-0"
                          id="basic-addon3"
                          @click="copyaddress('input3', 'social_links')"
                        >
                          <img src="@/assets/images/paste.svg" alt="icons" class="img-fluid" />
                        </span>
                      </div>
                      <!-- <div class="error" v-if="submitted && !item.link_value.required">
                                                Link is required
                      </div>-->
                      <div class="error" v-if="submitted && !item.link_value.url">Enter a Valid URL</div>
                    </div>
                    <!-- Delete  -->
                    <div class="ms-2">
                      <button
                        title="Delete"
                        data-toggle="tooltip"
                        data-placement="top"
                        type="button"
                        id="btn"
                        class="btn shadow-none border_box"
                        style="cursor: pointer;"
                        @click="index!=0?socials.splice(index, 1):'return false'"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="var(--navy-blue)"
                          viewBox="0 0 320 512"
                        >
                          <path
                            d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- TABLE ROW END -->

              <!-- TABLE ROW 2 START -->
              <div class="col-md-12">
                <div class="form-box">
                  <div class="mb-2 d-flex align-items-center">
                    <div class="w-50">
                      <label for="text">Economics:</label>
                    </div>
                    <!-- Add -->
                    <div class="w-50 text-end">
                      <button
                        title="Add new row"
                        data-toggle="tooltip"
                        data-placement="top"
                        type="button"
                        id="btn"
                        class="btn shadow-none border_box"
                        @click="economics.push({link_key: '',link_value: '',})"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="var(--navy-blue)"
                            viewBox="0 0 448 512"
                          >
                            <path
                              d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                  <div
                    v-for="(list, index) in economics"
                    :key="index"
                    class="border_box d-flex align-items-center px-3 py-2 mb-3"
                  >
                    <!-- Economics Name -->
                    <div class="w-50">
                      <div class="form-group">
                        <multiselect
                          v-model="list.link_key"
                          track-by="keys"
                          label="values"
                          :options="economics_options"
                        ></multiselect>
                      </div>
                      <div
                        class="error"
                        v-if="submitted && !list.link_key"
                      >economics Key is required</div>
                    </div>
                    <!-- Economics Value -->
                    <div class="w-50">
                      <div class="input-group form_liquidity">
                        <input
                          type="text"
                          class="form-control shadow-none m-0 ms-2"
                          id="buymin"
                          v-model="list.link_value"
                          aria-describedby="buymin"
                        />
                        <!-- <span class="input-group-text" id="buymin">$</span> -->
                      </div>
                      <div class="error" v-if="submitted && !list.link_value">Value is required</div>
                    </div>
                    <!-- Delete -->
                    <div class="ms-2">
                      <button
                        title="Delete"
                        data-toggle="tooltip"
                        data-placement="top"
                        type="button"
                        id="btn"
                        class="btn shadow-none border_box"
                        style="cursor: pointer;"
                        @click="index!=0?economics.splice(index, 1):'return false'"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="var(--navy-blue)"
                          viewBox="0 0 320 512"
                        >
                          <path
                            d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- TABLE ROW 2 ENDS -->

              <!-- TABLE ROW 3 START -->
              <div class="col-md-12">
                <div class="form-box">
                  <div class="mb-2 d-flex align-items-center">
                    <div class="w-50">
                      <label for="text">Token Allocation:</label>
                    </div>
                    <!-- Add -->
                    <div class="w-50 text-end">
                      <button
                        title="Add new row"
                        data-toggle="tooltip"
                        data-placement="top"
                        type="button"
                        id="btn"
                        class="btn shadow-none border_box"
                        @click="allocation.push({ link_key: '',link_value: '',})"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="var(--navy-blue)"
                            viewBox="0 0 448 512"
                          >
                            <path
                              d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                  <div
                    v-for="(list, index) in allocation"
                    :key="index"
                    class="border_box d-flex align-items-center px-3 py-2 mb-3"
                  >
                    <!-- Economics Name -->
                    <div class="w-50">
                      <div class="form-group">
                        <multiselect
                          v-model="list.link_key"
                          track-by="keys"
                          label="values"
                          :options="allocation_options"
                        ></multiselect>
                      </div>
                      <div
                        class="error"
                        v-if="submitted && !list.link_key"
                      >Allocation Key is required</div>
                    </div>
                    <!-- Economics Value -->
                    <div class="w-50">
                      <div class="input-group form_liquidity">
                        <input
                          type="text"
                          class="form-control shadow-none m-0 ms-2"
                          id="buymin"
                          v-model="list.link_value"
                          aria-describedby="buymin"
                        />
                        <!-- <span class="input-group-text" id="buymin">$</span> -->
                      </div>
                      <div class="error" v-if="submitted && !list.link_value">Value is required</div>
                    </div>
                    <!-- Delete -->
                    <div class="ms-2">
                      <button
                        title="Delete"
                        data-toggle="tooltip"
                        data-placement="top"
                        type="button"
                        id="btn"
                        class="btn shadow-none border_box"
                        style="cursor: pointer;"
                        @click="index!=0?allocation.splice(index, 1):'return false'"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="var(--navy-blue)"
                          viewBox="0 0 320 512"
                        >
                          <path
                            d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- TABLE ROW 3 ENDS -->

              <!-- TABLE ROW 4 START -->
              <div class="col-md-12">
                <div class="form-box">
                  <div class="mb-2 d-flex align-items-center">
                    <div class="w-50">
                      <label for="text">Use of funds:</label>
                    </div>
                    <!-- Add -->
                    <div class="w-50 text-end">
                      <button
                        title="Add new row"
                        data-toggle="tooltip"
                        data-placement="top"
                        type="button"
                        id="btn"
                        class="btn shadow-none border_box"
                        @click="use_of_funds.push({link_key: '',link_value: ''})"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="var(--navy-blue)"
                            viewBox="0 0 448 512"
                          >
                            <path
                              d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                  <div
                    v-for="(list, index) in use_of_funds"
                    :key="index"
                    class="border_box d-flex align-items-center px-3 py-2 mb-3"
                  >
                    <!-- Economics Name -->
                    <div class="w-50">
                      <div class="form-group">
                        <multiselect
                          v-model="list.link_key"
                          track-by="keys"
                          label="values"
                          :options="funds_options"
                        ></multiselect>
                      </div>
                      <div class="error" v-if="submitted && !list.link_key">Fund Key is required</div>
                    </div>
                    <!-- Economics Value -->
                    <div class="w-50">
                      <div class="input-group form_liquidity">
                        <input
                          type="text"
                          class="form-control shadow-none m-0 ms-2"
                          @keypress="keyChange($event, decimalLength)"
                          onpaste="return false;"
                          id="buymin"
                          v-model="list.link_value"
                          aria-describedby="buymin"
                        />
                        <span class="input-group-text" id="buymin">%</span>
                      </div>
                      <div class="error" v-if="submitted && !list.link_value">Value is required</div>
                    </div>
                    <!-- Delete -->
                    <div class="ms-2">
                      <button
                        title="Delete"
                        data-toggle="tooltip"
                        data-placement="top"
                        type="button"
                        id="btn"
                        class="btn shadow-none border_box"
                        style="cursor: pointer;"
                        @click="index!=0?use_of_funds.splice(index, 1):'return false'"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="var(--navy-blue)"
                          viewBox="0 0 320 512"
                        >
                          <path
                            d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- TABLE ROW 4 ENDS -->

              <!-- TABLE ROW 5 START -->
              <div class="col-md-12">
                <div class="form-box">
                  <div class="mb-2 d-flex align-items-center">
                    <div class="w-50">
                      <label for="text">Fund Raising:</label>
                    </div>
                    <!-- Add -->
                    <div class="w-50 text-end">
                      <button
                        title="Add new row"
                        data-toggle="tooltip"
                        data-placement="top"
                        type="button"
                        id="btn"
                        class="btn shadow-none border_box"
                        @click="fund_raising.push({link_key: '',link_value: ''})"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="var(--navy-blue)"
                            viewBox="0 0 448 512"
                          >
                            <path
                              d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"
                            />
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                  <div
                    v-for="(list, index) in fund_raising"
                    :key="index"
                    class="border_box d-flex align-items-center px-3 py-2 mb-3"
                  >
                    <!-- Economics Name -->
                    <div class="w-50">
                      <div class="form-group">
                        <multiselect
                          v-model="list.link_key"
                          track-by="keys"
                          label="values"
                          :options="fund_raising_options"
                        ></multiselect>
                      </div>
                      <div
                        class="error"
                        v-if="submitted && !list.link_key"
                      >Fund Raising Key is required</div>
                    </div>
                    <!-- Economics Value -->
                    <div class="w-50">
                      <div class="input-group form_liquidity">
                        <input
                          type="text"
                          class="form-control shadow-none m-0 ms-2"
                          id="buymin"
                          v-model="list.link_value"
                          aria-describedby="buymin"
                        />
                        <!-- <span class="input-group-text" id="buymin">%</span> -->
                      </div>
                      <div class="error" v-if="submitted && !list.link_value">Value is required</div>
                    </div>
                    <!-- Delete -->
                    <div class="ms-2">
                      <button
                        title="Delete"
                        data-toggle="tooltip"
                        data-placement="top"
                        type="button"
                        id="btn"
                        class="btn shadow-none border_box"
                        style="cursor: pointer;"
                        @click="index!=0?fund_raising.splice(index, 1):'return false'"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="var(--navy-blue)"
                          viewBox="0 0 320 512"
                        >
                          <path
                            d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- TABLE ROW 5 ENDS -->

              <!-- COMPANY HIGHLIGHT -->
              <div class="col-md-12">
                <div
                  class="form-group"
                  :class="{ 'form-group--error': $v.form.company_highlight.$error, }"
                >
                  <label for="selectbuy" class="form-label">Company Highlights</label>
                  <div class="input-group mb-3">
                    <textarea
                      class="form-control"
                      name="buy_desc"
                      id
                      cols="50"
                      rows="3"
                      v-model.trim="$v.form.company_highlight.$model"
                    ></textarea>
                  </div>
                  <div
                    class="error"
                    v-if="submitted && !$v.form.company_highlight.required"
                  >Company Highlight is required.</div>
                </div>
              </div>

              <!-- DISCLAIMER -->
              <div class="col-md-12">
                <div
                  class="form-group"
                  :class="{ 'form-group--error': $v.form.disclaimer.$error, }"
                >
                  <label for="selectbuy" class="form-label">Disclaimer</label>

                  <div class="input-group mb-3">
                    <textarea
                      class="form-control"
                      name="buy_desc"
                      id
                      cols="50"
                      rows="3"
                      v-model.trim="$v.form.disclaimer.$model"
                    ></textarea>
                  </div>

                  <div
                    class="error"
                    v-if="submitted && !$v.form.disclaimer.required"
                  >Description is required</div>
                </div>
              </div>

              <!-- PROJECT SUMMARY -->
              <div class="col-xl-12 col-lg-12 col-md-12 col-12 mb-3">
                <div
                  class="form-group"
                  :class="{ 'form-group--error': $v.form.project_summary.$error, }"
                >
                  <label for="selectbuy" class="form-label">Project Summary</label>
                  <div class="input-group mb-3">
                    <textarea
                      class="form-control"
                      name="buy_desc"
                      id
                      cols="100"
                      rows="3"
                      v-model.trim="$v.form.project_summary.$model"
                    ></textarea>
                  </div>
                  <div
                    class="error"
                    v-if="submitted && !$v.form.project_summary.required"
                  >Project summary is required</div>
                </div>
              </div>
              <div class="col-12 mt-5">
                <div class="form-group btn-submit">
                  <div class="spinner-border" role="status" v-if="loading">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <button type="submit" class="btn btn-primary" id="submit" v-if="!loading">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- </Layout> -->
  </div>
</template>

<script>
// import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";Layou
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import { required, url } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import ApiClass from "../../api/api";
import _ from "lodash";

export default {
  page: {
    title: "Edit Token",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: {
    // Layou
    Multiselect
  },

  data() {
    return {
      pdflink: null,
      tokenID: null,
      value: "",
      formatted: "",
      selected: "",
      formatted1: "",
      selected1: "",
      decimalLength: "",
      socials: [
        {
          link_key: "",
          link_value: ""
        }
      ],
      social_options: [
        { keys: "facebook", values: "Facebook" },
        { keys: "twitter", values: "Twitter" },
        { keys: "linkedin", values: "LinkdIn" },
        { keys: "telegram", values: "Telegram" }
      ],
      economics: [
        {
          link_key: "",
          link_value: ""
        }
      ],
      economics_options: [
        { keys: "max_token", values: "Max Token" },
        { keys: "circulating", values: "Circulating" },
        { keys: "market_cap", values: "Market Cap" },
        { keys: "initial_supply", values: "Initial Supply" },
        { keys: "token_type", values: "Token Type" },
        { keys: "issuer", values: "Issuer" }
      ],
      allocation: [
        {
          link_key: "",
          link_value: ""
        }
      ],
      allocation_options: [
        { keys: "team", values: "Team" },
        { keys: "advisors", values: "Advisors" },
        { keys: "treasury", values: "Treasury" },
        { keys: "marketing", values: "Marketing" },
        { keys: "staking", values: "Staking" },
        { keys: "play_earn", values: "Play_earn" },
        { keys: "seed_round", values: "Seed Round" },
        { keys: "private_round", values: "Private Round" },
        { keys: "public_round", values: "Public Round" },
        { keys: "float_liquidity", values: "Float Liquidity" }
      ],
      use_of_funds: [
        {
          link_key: "",
          link_value: ""
        }
      ],
      funds_options: [
        { keys: "liquidity", values: "Liquidity" },
        { keys: "business_development", values: "Business Development" },
        { keys: "marketing_and_Bounty", values: "Marketing and Bounty" },
        { keys: "reserve", values: "Reserve" }
      ],
      fund_raising: [
        {
          link_key: "",
          link_value: ""
        }
      ],
      fund_raising_options: [
        { keys: "total_round", values: "Total Round" },
        { keys: "raise_desc", values: "Raise Desc" },
        { keys: "min_invest", values: "Min Invest" },
        { keys: "coin_type", values: "Coin Type" },
        { keys: "offer_type", values: "Offer Type" }
      ],
      gallery: [
        {
          link_key: "",
          link_value: ""
        }
      ],
      rounds: [
        {
          link_key: "",
          link_value: ""
        }
      ],
      rounds_options: [
        { keys: "started_at", values: "Started At" },
        { keys: "expired_at", values: "Expired At" },
        { keys: "price", values: "Price" },
        { keys: "currency", values: "Currency" }
      ],
      hashtag_options: [],
      partnership_options: [],
      title: "Edit Token",
      title2: "Edit Rounds",
      items: [
        {
          text: "Tokens",
          href: "#"
        },
        {
          text: "Edit Token",
          active: true
        }
      ],
      submitted: false,
      verify_options: [
        { key: "1", value: "1" },
        { key: "2", value: "2" },
        { key: "3", value: "3" },
        { key: "4", value: "4" },
        { key: "5", value: "5" }
      ],
      status_options: [
        { key: "1", value: "Active" },
        { key: "0", value: "Not Active" }
      ],
      loading: false,
      new_image: "",
      form: {
        verification_score: null,
        expired_at: "",
        started_at: "",
        distribution_at: "",
        gallery: [],
        verify_score: {},
        total_score: 5,
        symbol: "",
        disclaimer: "",
        project_summary: "",
        partnership: "",
        company_highlight: "",
        image: null,
        video_link: "",
        token_url: "",
        social_links: "",
        hashtag: [],
        name: ""
      }
    };
  },
  mounted() {
    this.tokenID = this.$route.params.id;
    this.getToken();
    // console.log(this.form.started_at);
  },
  methods: {
    onContext(ctx) {
      this.formatted = ctx.selectedFormatted;
      // console.log(this.formatted);
      this.selected = ctx.selectedYMD;
      // console.log(this.selected);
      // console.log(this.form.started_at);
    },
    onContext1(ctx) {
      this.formatted1 = ctx.selectedFormatted;
      this.selected1 = ctx.selectedYMD;
    },
    async pdfupload() {
      if (event.target.files.length == 0) {
        this.form.image = null;
      }
      this.form.image = event.target.files[0];
      let imageData = new FormData();
      imageData.append("file", this.form.image);
      let header = {
        "Content-Type": "multipart/form-data;"
      };
      var result = await ApiClass.postNodeRequest(
        "launchpad/imageupload",
        true,
        imageData,
        header
      );
      if (result.data.status_code == 0) {
        this.form.image = null;
        return Swal.fire({
          position: "center",
          icon: "error",
          title: result.data.message,
          showConfirmButton: true,
          timer: 5000
        });
      }
      this.form.image = this.pdflink = result.data.data;
    },
    async galleryImages(index) {
      if (event.target.files.length == 0) {
        this.whitepaper_link = null;
      }
      let gallery = event.target.files[0];
      this.gallery[index] = URL.createObjectURL(event.target.files[0]);
      this.gallery.push("");
      this.gallery.pop();
      let imageData = new FormData();
      imageData.append("file", gallery);
      let header = {
        "Content-Type": "multipart/form-data;"
      };
      var result = await ApiClass.postNodeRequest(
        "launchpad/imageupload",
        true,
        imageData,
        header
      );
      if (result.data.status_code == 0) {
        return Swal.fire({
          position: "center",
          icon: "error",
          title: result.data.message,
          showConfirmButton: true,
          timer: 5000
        });
      }
      this.form.gallery.push(result.data.data);
    },
    async copyaddress(id, vars) {
      console.log(id, vars);
      var paste = document.getElementById(id);
      paste.focus();
      var data = await navigator.clipboard.readText();
      // console.log(data)
      this.form[vars] = document.getElementById(id).value = data;
    },
    addTag(newTag) {
      const tag = {
        name: newTag
        // code: newTag
      };
      // console.log(tag);
      this.hashtag_options.push(tag);
      this.form.hashtag.push(tag);
      // console.log(JSON.parse(JSON.stringify(this.form.hashtag)));
    },
    addTag1(newTag) {
      const tag = {
        name: newTag
      };
      this.partnership_options.push(tag);
      this.form.partnership.push(tag);
    },

    async getToken() {
      var result = await ApiClass.getNodeRequest(
        "launchpad/getone/" + this.$route.params.id,
        true
      );
      if (result.data.status_code == 1 && result.data.data != "undefined") {
        console.log({ result });
        let mainData = result.data.data;
        this.form.name = mainData?.name;
        this.form.symbol = mainData?.symbol;
        this.form.token_url = mainData?.token_url;
        this.pdflink = mainData?.whitepaper_link;
        let pdf = mainData?.whitepaper_link.split("/");
        this.form.image = pdf[pdf.length - 1];
        this.form.video_link = mainData?.video_link;
        this.form.started_at = mainData?.started_at;
        this.form.expired_at = mainData?.expired_at;
        this.form.distribution_at = mainData?.distribution_at;
        this.form.company_highlight = mainData?.company_highlight;
        this.form.disclaimer = mainData?.disclaimer;
        this.form.project_summary = mainData?.project_summary;
        this.hashtag_options = mainData?.hash_tags;
        this.form.partnership = mainData?.partnerships;
        this.form.verify_score = {
          key: mainData?.verification_score.toString(),
          value: mainData?.verification_score.toString()
        };
        this.form.hashtag = Object.values(mainData?.hash_tags);
        this.gallery = mainData?.gallery;
        this.gallery.map(v => {
          let nnn = v.split("/");
          this.form.gallery.push(nnn[nnn.length - 1]);
        });
        this.partnership_options = [];
        for (let i = 0; i < this.form.partnership.length; i++) {
          this.partnership_options.push({ name: this.form.partnership[i] });
        }
        this.form.partnership = this.partnership_options;
        this.socials = [];
        for (let link in mainData.social_media_link) {
          this.socials.push({
            link_key: { keys: link, values: link },
            link_value: mainData.social_media_link[link]
          });
        }
        this.economics = [];
        for (let link in mainData.token_economics) {
          this.economics.push({
            link_key: { keys: link, values: link },
            link_value: mainData.token_economics[link]
          });
        }
        this.allocation = [];
        for (let link in mainData.token_allocation) {
          this.allocation.push({
            link_key: { keys: link, values: link },
            link_value: mainData.token_allocation[link]
          });
        }
        this.use_of_funds = [];
        for (let link in mainData.use_of_funds) {
          this.use_of_funds.push({
            link_key: { keys: link, values: link },
            link_value: mainData.use_of_funds[link]
          });
        }
        this.fund_raising = [];
        for (let link in mainData.fund_raising) {
          this.fund_raising.push({
            link_key: { keys: link, values: link },
            link_value: mainData.fund_raising[link]
          });
        }
      } else {
        this.$router.push({ name: "token_get" });
      }
    },
    async save() {
      this.submitted = true;
      if (this.$v.form.$invalid) {
        console.log(this.$v);
        return;
      }
      this.submitted = false;
      this.loading = true;
      //    let tags = [];
      //     this.form.hashtag.map((v) => tags.push(v.name));
      let socialLinks = {};
      this.socials.map(v => {
        socialLinks[v.link_key?.keys] = v.link_value;
      });
      let partnerships = [];
      this.form.partnership.map(v => partnerships.push(v.name));
      let token_economics = {};
      this.economics.map(v => {
        token_economics[v.link_key.keys] = v.link_value;
      });
      let token_allocation = {};
      this.allocation.map(v => {
        token_allocation[v.link_key.keys] = v.link_value;
      });
      let funds = {};
      this.use_of_funds.map(v => {
        funds[v.link_key.keys] = v.link_value;
      });
      let raising = {};
      this.fund_raising.map(v => {
        raising[v.link_key.keys] = v.link_value;
      });
      let formData = {
        // image: this.new_image,
        name: this.form.name,
        verification_score: this.form.verify_score.value,
        symbol: this.form.symbol,
        hash_tags: this.form.hashtag,
        social_media_link: socialLinks,
        token_url: this.form.token_url,
        video_link: this.form.video_link,
        project_summary: this.form.project_summary,
        whitepaper_link: this.form.image,
        token_economics: token_economics,
        token_allocation: token_allocation,
        company_highlight: this.form.company_highlight,
        use_of_funds: funds,
        fund_raising: raising,
        // partnerships: this.form.partnership,
        gallery: this.form.gallery,
        disclaimer: this.form.disclaimer,
        started_at: this.form.started_at,
        expired_at: this.form.expired_at,
        distribution_date: this.form.distribution_at,
        
      };
      if (partnerships.length > 0) {
        Object.assign(formData, { partnerships: partnerships });
      }

      // formData = _.compact(formData)
      console.log({ hihi: _.compact(formData) });
    //  formData =  _.flatten(formData)
      // Remove all undefined values from the person object
      Object.keys(formData).forEach(keys =>
       formData[keys]?.hasOwnProperty(undefined)  || keys == undefined || formData[keys] === undefined || formData[keys] == ""
          ? delete formData[keys]
          : {}
      );

      var result = await ApiClass.updatenodeRequest(
        "launchpad/update_token/" + this.tokenID,
        true,
        formData
      );
      if (result.data.status_code == 1) {
        this.loading = false;
        Swal.fire({
          position: "center",
          icon: "success",
          title: result.data.message,
          showConfirmButton: true,
          timer: 5000
        });
        this.$router.push("/get/tokens");
      } else {
        this.loading = false;
        Swal.fire({
          position: "center",
          icon: "error",
          title: result.data.message,
          showConfirmButton: true,
          timer: 2000
        });
      }
    },
    keyChange(event, decimal = null) {
      if (decimal == 0 && event.charCode == 46) {
        return event.preventDefault();
      }

      let res =
        event.charCode != 8 &&
        ((event.charCode >= 48 && event.charCode <= 57) ||
          (event.charCode == 46 && event.target.value.indexOf(".") == -1));

      if (decimal != null && res && event.target.value.indexOf(".") != -1) {
        res = event.target.value.split(".")[1].length < decimal;
      }

      return res ? res : event.preventDefault();
    }
  },
  validations: {
    socials: {
      $each: {
        link_key: {},
        link_value: {
          url
        }
      }
    },
    form: {
      started_at: { required },
      expired_at: { required },
    //   distribution_at: { required },
      name: { required },
      verify_score: { required },
      hashtag: { required },
      project_summary: { required },
      company_highlight: { required },
      disclaimer: { required },
      gallery: { required },
      total_score: { required },
      symbol: { required },
      partnership: {
        $each: {
          name: {
            url
          }
        }
      },
      image: { required },
      video_link: { required, url },
      token_url: { required, url }
    }
  }
};
</script>

<style scoped>
.add_coin_form_box {
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  border-radius: 5px;
  padding: 15px 15px;
}

.form-group.btn-submit {
  text-align: center;
}

.form-group .currency-image {
  text-align: center;
  max-width: 44px;
  height: 44px;
  margin: 0 auto;
  border-radius: 26px;
  padding: 0;
  margin-top: 18px;
  margin-left: 0;
}

.form-group .currency-image img {
  max-width: 39px;
  margin-top: 5px;
}

.border_box {
  border: 1px solid #ebecf1;
  border-radius: 5px;
}
</style>
